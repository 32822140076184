define('m03/controllers/impressum', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    wuerth: false, //don't change this!
    swg: false, //don't change this!
    itw: false, //don't change this!
    reisser: true, //don't change this!

    selectedLanguage: "DE",

    init: function init() {
      this._super();
    },

    spracheDeutsch: (function () {
      var self = this;

      if (self.get("selectedLanguage") === "DE") {
        return true;
      } else {
        return false;
      }
    }).property('selectedLanguage')

  });

});